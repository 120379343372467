import styled from 'styled-components';

export const Button = styled.button`
  background-color: #4caf50; 
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #45a049;
  }

  svg {
    margin-left: 8px;
  }
`;

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const PopupContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 630px;
  max-height: 95vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;


export const PopupTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Input = styled.input`
  padding: 8px 65px 8px 12px;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

export const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

export const LabelText = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const TextAreaDescription = styled.textarea`
  width: 100%;
  height: 180px;
  padding: 8px;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  font-family: Arial, sans-serif;

  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`;

export const TextAreaAdditional = styled.textarea`
  width: 100%;
  height: 140px;
  padding: 8px;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  font-family: Arial, sans-serif;

  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`;

export const LabelInputGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px; /* Space between label and input */
  width: 100%;
`;

export const LabelTextAligned = styled.label`
  flex: 0 0 150px; /* Fixed width for labels */
  font-size: 14px;
  color: #333;
  margin-top: 8px; /* Align with text area */
`;

export const AddButton = styled(Button)`
  position: relative;
  align-self: flex-end;
  width: 160px;
  margin-top: 10px;
`;

export const ButtonText = styled.span`
  margin-left: 35px;
`;

export const AddFieldButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #fffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* Layout for Responsibilities and Qualifications */
export const FieldGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const JobTypeContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  input[type='radio'] {
    margin-right: 5px;
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  gap: 10px;

  input {
    flex: 1;
  }

  svg {
    margin-left: -25px;
    margin-top: 12px;
  }
`;

export const RemoveFieldButton = styled.button`
  position: absolute;
  right: 40px; /* Position the "-" button to the left of the "+" button */
  background: none;
  border: none;
  color: #fffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToggleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export const ToggleButton = styled.a<{ isActive: boolean }>`
  padding: 8px 16px;
  border: 1px solid ${({ isActive }) => (isActive ? '#4caf50' : '#ccc')};
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? '#e8f5e9' : '#f9f9f9')};
  color: ${({ isActive }) => (isActive ? '#4caf50' : '#666')};
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-color: #4caf50;
  }

  &:focus {
    outline: none;
  }
`;

export const InputGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;