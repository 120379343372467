import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { LoginPageWrapper, LoginBox, IllustrationWrapper } from './loginPage.styled';
import loginImage from 'assets/photos/login.svg';
import { useAuth } from '../../context/authContext/AuthContext';
import { signIn } from 'api/api';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
    const { data } = await signIn({ username, password })
        
      if (data.data?.token) {
        login(data.data.token);
        navigate('/manageCareers');
      } else {
         Swal.fire({
           icon: 'error',
           title: 'Authentication Failed',
           text: 'Invalid username or password. Please try again.',
           confirmButtonColor: '#d33',
           confirmButtonText: 'OK',
         });
      }
    } catch (error) {
      console.log(error);

      alert('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginPageWrapper>
      <LoginBox>
        <h2>Welcome to Iguan Systems</h2>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <label htmlFor="username">Username</label>
          <input
            type="username"
            id="username"
            placeholder="Enter your username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />

          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Login'}
          </button>
        </form>
      </LoginBox>
      <IllustrationWrapper>
        <img src={loginImage} alt="Login Illustration" />
      </IllustrationWrapper>
    </LoginPageWrapper>
  );
};

export default LoginPage;
