import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import PlusButton from 'components/atoms/plus/plusButton';
import Container from 'components/atoms/container';
import { ButtonWrapper } from 'pages/productsPage/productsPage.styled';
import { positionsList } from 'pages/careerPage/mock';
import Search from 'components/atoms/search';
import PositionCart from 'components/molecules/positionCart';
import { IPositionProps, ICvProps } from 'pages/careerPage/types';
import NoData from 'components/atoms/noData';
import Pagination from 'components/atoms/pagination';
import { getAllCareers, updateCareer, getAllCvs, deleteCV, downloadCV } from '../../api/api';
import {
  OurPositions,
  BTitle,
  CVRow,
  CardGrid,
  Card,
  ActionButtons,
  IconButton,
  LogoutButtonWrapper,
  LogoutButton,
} from './adminPage.styled';
import { FaTrashAlt, FaDownload } from 'react-icons/fa';
import {
  JobsCol,
  JobsRow,
  PositionItem,
  PositionList,
  PositionListWrapper,
  SearchWrapper,
} from 'pages/careerPage/careerPage.styled';
import AddPositionPopup from 'components/molecules/addPositionPopup/addPositionPopup';
import UpdatePositionPopup from 'components/molecules/updatePositionPopup/updatePositionPopup';
import { useAuth } from '../../context/authContext/AuthContext';

const AdminPage = () => {
  const [positions, setPositions] = useState<IPositionProps[]>([]);
  const [cvs, setCVs] = useState<ICvProps[]>([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(3);
  const [perCVPage] = useState(3);
  const [positionFilter, setPositionFilter] = useState('all');
  const [positionCVFilter, setPositionCVFilter] = useState('all');
  const [totalCVCount, setTotalCVCount] = useState(1);
  const [currentCVPage, setCurrentCVPage] = useState(1);
  const [searchableValue, setSearchableValue] = useState('');
  const [search, setSearch] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [currentCareer, setCurrentCareer] = useState<IPositionProps | null>(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (positionFilter === 'all') {
      getAllCareers({
        search: searchableValue,
        page: currentPage,
        limit: perPage,
      }).then(res => {
        setPositions(res.data.jobs);
        setTotalCount(res.data.jobCount);
      });
    } else {
      getAllCareers({
        type: positionFilter,
        search: searchableValue,
        page: currentPage,
        limit: perPage,
      })
        .then(res => {
          setPositions(res.data.jobs);
          setTotalCount(res.data.jobCount);
        })
        .catch(e => {
          console.log(e);
          setPositions([]);
        });
    }
  }, [positionFilter, searchableValue, currentPage, perPage, totalCount]);

  useEffect(() => {
    if (positionCVFilter === 'all') {
      getAllCvs({
        page: currentCVPage,
        limit: perCVPage,
      }).then(res => {
        setCVs(res.data.cvs);
        setTotalCVCount(res.data.cvCount);
      });
    } else {
      getAllCvs({
        type: positionCVFilter,
        page: currentCVPage,
        limit: perCVPage,
      })
        .then(res => {
          setCVs(res.data.cvs);
          setTotalCVCount(res.data.cvCount);
        })
        .catch(e => {
          console.log(e);
          setCVs([]);
        });
    }
  }, [positionCVFilter, currentCVPage, perCVPage, totalCVCount]);

  const handleAddCareer = () => {
    setIsPopupOpen(false); // Close the popup
  };

  const handleChangePosition = (value: string) => {
    setPositionFilter(value);
  };

  const handleChangeCVPosition = (value: string) => {
    setPositionCVFilter(value);
  };

  const handleSearch = (value: string) => {
    setSearchableValue(value);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeCVPage = (page: number) => {
    setCurrentCVPage(page);
  };

  const handleDeleteCareer = (id: string) => {
    setPositions(prevPositions => prevPositions.filter(position => position._id !== id));
    setTotalCount(prevCount => prevCount - 1);
  };

  const handleUpdateCareer = async (updatedData: IPositionProps) => {
    if (!currentCareer) return;

    try {
      await updateCareer(currentCareer._id, updatedData);

      setIsUpdatePopupOpen(false);
    } catch (error) {
      console.error('Failed to update career:', error);
    }
  };

  const openUpdatePopup = (career: IPositionProps) => {
    setCurrentCareer(career);
    setIsUpdatePopupOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action will permanently delete the CV.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await deleteCV(id);
        setCVs(prevCVs => prevCVs.filter(cv => cv._id !== id));
        setTotalCVCount(prevCount => prevCount - 1);

        Swal.fire('Deleted!', 'The CV has been deleted.', 'success');
      }
    } catch (error) {
      console.error('Failed to delete CV:', error);
      Swal.fire('Error', 'Failed to delete CV. Please try again.', 'error');
    }
  };

  const handleDownload = async (id: string) => {
    await downloadCV(id);
  };
  const handleLogout = () => {
    logout(); 
    navigate('/login'); 
  };
  return (
    <div>
      <Container>
        <LogoutButtonWrapper>
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </LogoutButtonWrapper>
        <ButtonWrapper>
          <PlusButton onClick={() => setIsPopupOpen(true)} />
          <AddPositionPopup
            isOpen={isPopupOpen}
            togglePopup={() => setIsPopupOpen(false)}
            onAddCareer={handleAddCareer}
          />
        </ButtonWrapper>
        <OurPositions>
          <BTitle>Open positions</BTitle>
          <PositionListWrapper>
            <PositionList>
              {positionsList.map(item => (
                <PositionItem
                  key={item.id}
                  className={item.value === positionFilter ? 'active' : ''}
                  onClick={() => handleChangePosition(item.value)}
                >
                  {item.label}
                </PositionItem>
              ))}
            </PositionList>
          </PositionListWrapper>
          <SearchWrapper>
            <Search
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onClick={() => handleSearch(search)}
            />
          </SearchWrapper>
          <JobsRow>
            {positions && positions.length ? (
              positions.map(job => {
                return (
                  <JobsCol key={job._id}>
                    <PositionCart
                      data={job}
                      onDelete={handleDeleteCareer}
                      onUpdate={() => openUpdatePopup(job)}
                    />
                  </JobsCol>
                );
              })
            ) : (
              <NoData />
            )}
          </JobsRow>
          <Pagination
            count={totalCount}
            perPage={perPage}
            activePage={currentPage}
            handleChangePage={handleChangePage}
          />
        </OurPositions>
        {currentCareer && (
          <UpdatePositionPopup
            isOpen={isUpdatePopupOpen}
            togglePopup={() => setIsUpdatePopupOpen(false)}
            onUpdateCareer={handleUpdateCareer}
            careerData={currentCareer}
          />
        )}
        <OurPositions>
          <BTitle>Received CVs</BTitle>
          <PositionListWrapper>
            <PositionList>
              {positionsList.map(item => (
                <PositionItem
                  key={item.id}
                  className={item.value === positionCVFilter ? 'active' : ''}
                  onClick={() => handleChangeCVPosition(item.value)}
                >
                  {item.label}
                </PositionItem>
              ))}
            </PositionList>
          </PositionListWrapper>
        </OurPositions>
        <CVRow>
          <CardGrid>
            {/* {positionsList.map(item => (
              <Card key={item.id} onClick={() => handleCardClick(item.id)}>
                <div>{item.id}</div>
                <div>CV</div>
              </Card>
            ))} */}
            {cvs && cvs.length ? (
              cvs.map(cv => {
                return (
                  <Card key={cv._id}>
                    <div>{cv.position}</div>
                    <div>CV</div>
                    <ActionButtons>
                      <IconButton onClick={() => handleDownload(cv._id)} title="Download CV">
                        <FaDownload />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(cv._id.toString())} title="Delete CV">
                        <FaTrashAlt />
                      </IconButton>
                    </ActionButtons>
                  </Card>
                );
              })
            ) : (
              <NoData />
            )}
          </CardGrid>
        </CVRow>
        <Pagination
          count={totalCVCount}
          perPage={perCVPage}
          activePage={currentCVPage}
          handleChangeCVPage={handleChangeCVPage}
        />
      </Container>
    </div>
  );
};

export default AdminPage;
