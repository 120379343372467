import axios from 'axios';

export const getAllProducts = async () => {
  return await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/products`);
};

export const addProduct = async body => {
  return await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/products/add`, body);
};

export const getAllCareers = async ({ limit = 8, page = 1, type = '', search = '' }) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/career?limit=${limit}&page=${page}&type=${type}&search=${search}`,
  );
};

export const getCareerById = async (id = '') => {
  return await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/career/${id}`);
};

export const addCareer = async body => {
  return await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/career/create`, body);
};

export const deleteCareer = async (id = '') => {
  return await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/career/delete/${id}`);
};

export const updateCareer = async (id = '', body) => {
  return await axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/career/update/${id}`, body);
};

export const getAllCvs = async ({ limit = 8, page = 1, type = '' }) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/cv/?limit=${limit}&page=${page}&type=${type}`,
  );
};


export const attachCV = async (
  file,
  data,
) => {
  const formData = new FormData();
 formData.append('file', file);

 Object.entries(data).forEach(([key, value]) => {
   formData.append(key, value);
 });

  return await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/cv/attach`, formData, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
    }
  });
};

export const deleteCV = async (id = '') => {
  return await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/cv/delete/${id}`);
};

export const downloadCV = async (id = '') => {

 try {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cv/download/${id}`, {
     responseType: 'blob',
   });

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', `CV_${id}.pdf`); 
    document.body.appendChild(link);
    link.click();
    link.remove();
 } catch (error) {
   console.error('Error downloading CV', error);
   alert('There was an error downloading the CV.');
 }
};

export const sendCv = async body => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_URL}/api/sendCv`,
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const contactUs = async body => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_URL}/api/contactUs`,
    data: body,
    headers: { 'Content-Type': 'application/json' },
  });
};

export const signIn = async body => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_URL}/api/auth/admin/sign-in`,
    data: body,
    headers: { 'Content-Type': 'application/json' },
  });
}
