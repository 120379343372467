import styled from 'styled-components';
import { devices } from '../../../styles/global';

const StyledPlusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #4caf50;
  background-color: transparent;
  border: 2px solid #4caf50;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f8f5;
  }

  .plus-icon {
    margin-left: 8px;
    font-size: 18px;
  }

  /* Responsive adjustments */
  @media ${devices.tablet} {
    padding: 8px 16px;
    font-size: 15px;
  }

  @media ${devices.mobileL} {
    padding: 6px 12px;
    font-size: 14px;
  }

  @media ${devices.mobileM} {
    padding: 5px 10px;
    font-size: 13px;
  }
`;

export default StyledPlusButton;