import React, { useState } from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { CgMathMinus } from 'react-icons/cg';
import {
  PopupOverlay,
  PopupContainer,
  PopupTitle,
  Form,
  Input,
  Select,
  AddButton,
  ButtonText,
  TextAreaAdditional,
  AddFieldButton,
  FieldGroup,
  RemoveFieldButton,
  ToggleGroup,
  ToggleButton,
  LabelInputGroup,
  LabelTextAligned,
  InputGroups,
  TextAreaDescription,
} from './addPositionPopup.styled';
import timeIcon from 'assets/photos/time.svg';
import { addCareer } from 'api/api';

interface AddPositionPopupProps {
  isOpen: boolean;
  togglePopup: () => void;
  onAddCareer: () => void;
}

const AddPositionPopup: React.FC<AddPositionPopupProps> = ({ isOpen, togglePopup }) => {
  const [category, setCategory] = useState('Development');
  const [jobTitle, setJobTitle] = useState('');
  const [level, setLevel] = useState('Intern');
  const [jobDescription, setJobDescription] = useState('');
  const [responsibilities, setResponsibilities] = useState<string[]>(['']);
  const [qualifications, setQualifications] = useState<string[]>(['']);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [jobType, setJobType] = useState<'Full time' | 'Part time'>('Full time');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const newPosition = {
      type: category,
      position: jobTitle,
      level: level,
      jobDescription,
      responsibilities,
      qualifications,
      additionalInfo,
      jobType,
    };

    if (jobTitle.trim() === '') {
      e.preventDefault();
      
      setError('Job title is required');
      return;
    }
    
    try {
      await addCareer(newPosition);

      togglePopup(); // Close the popup
    } catch (error) {
      console.error('Failed to create career:', error);
    }

    setError('');
  };

  const handleResponsibilityChange = (index: number, value: string) => {
    const updatedResponsibilities = [...responsibilities];
    updatedResponsibilities[index] = value;
    setResponsibilities(updatedResponsibilities);
  };

  const handleAddResponsibility = () => {
    setResponsibilities([...responsibilities, '']);
  };

  const handleRemoveResponsibility = (index: number) => {
    const updatedResponsibilities = responsibilities.filter((_, i) => i !== index);
    setResponsibilities(updatedResponsibilities);
  };

  const handleAddQualification = () => {
    setQualifications([...qualifications, '']);
  };

  const handleQualificationChange = (index: number, value: string) => {
    const updatedQualifications = [...qualifications];
    updatedQualifications[index] = value;
    setQualifications(updatedQualifications);
  };

  const handleRemoveQualification = (index: number) => {
    const updatedQualifications = qualifications.filter((_, i) => i !== index);
    setQualifications(updatedQualifications);
  };

  return (
    <PopupOverlay onClick={togglePopup}>
      <PopupContainer onClick={e => e.stopPropagation()}>
        <PopupTitle>New Position</PopupTitle>
        <Form onSubmit={handleSubmit}>
          <LabelInputGroup>
            <LabelTextAligned>Category</LabelTextAligned>
            <Select value={category} onChange={e => setCategory(e.target.value)}>
              <option value="Development">Development</option>
              <option value="Design">Design</option>
              <option value="Marketing">Marketing</option>
            </Select>
          </LabelInputGroup>
          <LabelInputGroup>
            <LabelTextAligned>Job title</LabelTextAligned>
            <Input
              type="text"
              placeholder="Job title"
              value={jobTitle}
              onChange={e => setJobTitle(e.target.value)}
            />
          </LabelInputGroup>
          {error && <span style={{ color: 'red', textAlign: 'end' }}>{error}</span>}
          <LabelInputGroup>
            <LabelTextAligned>Level</LabelTextAligned>
            <Select onChange={e => setLevel(e.target.value)}>
              <option value="Intern">Intern</option>
              <option value="Junior">Junior</option>
              <option value="Mid">Mid</option>
              <option value="Senior">Senior</option>
            </Select>
          </LabelInputGroup>
          {/* Description */}
          <LabelInputGroup>
            <LabelTextAligned>Description</LabelTextAligned>
            <TextAreaDescription
              placeholder="Enter job description"
              value={jobDescription}
              onChange={e => setJobDescription(e.target.value)}
            />
          </LabelInputGroup>
          {/* Responsibilities */}
          <LabelInputGroup>
            <LabelTextAligned>Responsibilities</LabelTextAligned>
            <InputGroups>
              {responsibilities.map((res, index) => (
                <FieldGroup key={index}>
                  <Input
                    type="text"
                    placeholder="Add responsibility"
                    value={res}
                    onChange={e => handleResponsibilityChange(index, e.target.value)}
                  />
                  <RemoveFieldButton
                    type="button"
                    onClick={() => handleRemoveResponsibility(index)}
                  >
                    <CgMathMinus size={16} />
                  </RemoveFieldButton>
                  <AddFieldButton type="button" onClick={handleAddResponsibility}>
                    <CgMathPlus size={16} />
                  </AddFieldButton>
                </FieldGroup>
              ))}
            </InputGroups>
          </LabelInputGroup>
          <LabelInputGroup>
            {/* Qualifications */}
            <LabelTextAligned>Qualifications</LabelTextAligned>
            <InputGroups>
              {qualifications.map((qual, index) => (
                <FieldGroup key={index}>
                  <Input
                    type="text"
                    placeholder="Add qualification"
                    value={qual}
                    onChange={e => handleQualificationChange(index, e.target.value)}
                  />
                  <RemoveFieldButton type="button" onClick={() => handleRemoveQualification(index)}>
                    <CgMathMinus size={16} />
                  </RemoveFieldButton>
                  <AddFieldButton type="button" onClick={handleAddQualification}>
                    <CgMathPlus size={16} />
                  </AddFieldButton>
                </FieldGroup>
              ))}
            </InputGroups>
          </LabelInputGroup>
          {/* Additional Information */}
          <LabelInputGroup>
            <LabelTextAligned>Additional Information</LabelTextAligned>
            <TextAreaAdditional
              placeholder="Enter additional information"
              value={additionalInfo}
              onChange={e => setAdditionalInfo(e.target.value)}
            />
          </LabelInputGroup>
          <ToggleGroup>
            <img src={timeIcon} alt="time icon" />
            <ToggleButton
              isActive={jobType === 'Full time'}
              onClick={() => setJobType('Full time')}
            >
              Full time
            </ToggleButton>
            <ToggleButton
              isActive={jobType === 'Part time'}
              onClick={() => setJobType('Part time')}
            >
              Part time
            </ToggleButton>
          </ToggleGroup>
          <AddButton>
            <ButtonText>
              <span>Add</span>
            </ButtonText>
            <CgMathPlus size={24} />
          </AddButton>
        </Form>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default AddPositionPopup;
