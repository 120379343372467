import styled from 'styled-components';

export const LoginPageWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  font-family: Arial, sans-serif;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
    margin-top: 40px;
    gap: 30px;
  }
`;

export const LoginBox = styled.div`
  min-width: 450px;
  height: 450px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      font-size: 0.9rem;
      color: #555;
      margin-bottom: 5px;
    }

    input {
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 1rem;
      margin-bottom: 1rem;

      &:focus {
        border-color: #4caf50;
        outline: none;
      }
    }

    button {
      background-color: #6ebd44;
      color: #fff;
      border: none;
      padding: 10px;
      border-radius: 5px;
      font-size: 1rem;
      margin-top: 40px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #43a047;
      }
    }
  }
`;

export const IllustrationWrapper = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    img {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;
