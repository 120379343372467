import { useState } from 'react';
// import {
//   PopupOverlay,
//   PopupContainer,
//   PopupTitle,
//   Form,
//   Input,
//   Select,
//   AddButton,
//   LabelText,
//   ButtonText,
// } from './updatePositionPopup.styled';
import {
  PopupOverlay,
  PopupContainer,
  PopupTitle,
  Form,
  Input,
  Select,
  AddButton,
  ButtonText,
  TextAreaAdditional,
  AddFieldButton,
  FieldGroup,
  RemoveFieldButton,
  ToggleGroup,
  ToggleButton,
  LabelInputGroup,
  LabelTextAligned,
  InputGroups,
  TextAreaDescription,
} from '../addPositionPopup/addPositionPopup.styled';
import { updateCareer } from 'api/api';
import { CgMathPlus } from 'react-icons/cg';
import { CgMathMinus } from 'react-icons/cg';
import timeIcon from 'assets/photos/time.svg';

interface UpdatePositionPopupProps {
  isOpen: boolean;
  togglePopup: () => void;
  onUpdateCareer: (updatedData: any) => void;
  careerData: {
    type: string;
    position: string;
    level: string;
    jobDescription: string;
    responsibilities: string[];
    qualifications: string[];
    additionalInfo: string;
    jobType: 'Full time' | 'Part time';
    _id: string;
  };
}

const UpdatePositionPopup: React.FC<UpdatePositionPopupProps> = ({
  isOpen,
  togglePopup,
  onUpdateCareer,
  careerData,
}) => {
  const [category, setCategory] = useState(careerData.type);
  const [jobTitle, setJobTitle] = useState(careerData.position);
  const [level, setLevel] = useState(careerData.level);
  const [jobDescription, setJobDescription] = useState(careerData.jobDescription);
  const [responsibilities, setResponsibilities] = useState<string[]>(careerData.responsibilities);
  const [qualifications, setQualifications] = useState<string[]>(careerData.qualifications);
  const [additionalInfo, setAdditionalInfo] = useState(careerData.additionalInfo);
  const [jobType, setJobType] = useState(careerData.jobType);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const updatedData = {
      type: category,
      position: jobTitle,
      level,
      jobDescription,
      responsibilities,
      qualifications,
      additionalInfo,
      jobType,
    };

    if (jobTitle.trim() === '') {
      e.preventDefault();
      setError('Job title is required');
      return;
    }

    try {
      await updateCareer(careerData._id, updatedData);
      console.log(`Updated career with ID: ${careerData._id}`);
      onUpdateCareer(updatedData);
      togglePopup(); // Close the popup
    } catch (error) {
      console.error('Failed to update career:', error);
    }
    setError('');
  };

  const handleResponsibilityChange = (index: number, value: string) => {
    const updatedResponsibilities = [...responsibilities];
    updatedResponsibilities[index] = value;
    setResponsibilities(updatedResponsibilities);
  };

  const handleAddResponsibility = () => {
    setResponsibilities([...responsibilities, '']);
  };

  const handleRemoveResponsibility = (index: number) => {
    const updatedResponsibilities = responsibilities.filter((_, i) => i !== index);
    setResponsibilities(updatedResponsibilities);
  };

  const handleAddQualification = () => {
    setQualifications([...qualifications, '']);
  };

  const handleQualificationChange = (index: number, value: string) => {
    const updatedQualifications = [...qualifications];
    updatedQualifications[index] = value;
    setQualifications(updatedQualifications);
  };

  const handleRemoveQualification = (index: number) => {
    const updatedQualifications = qualifications.filter((_, i) => i !== index);
    setQualifications(updatedQualifications);
  };

  if (!isOpen) return null;

  // return (
  //   <PopupOverlay onClick={togglePopup}>
  //     <PopupContainer onClick={e => e.stopPropagation()}>
  //       <PopupTitle>Update Position</PopupTitle>
  //       <Form onSubmit={handleSubmit}>
  //         <label>
  //           <LabelText>Category</LabelText>
  //           <Select
  //             style={{ marginLeft: '-10px' }}
  //             value={category}
  //             onChange={e => setCategory(e.target.value)}
  //           >
  //             <option value="development">Development</option>
  //             <option value="design">Design</option>
  //             <option value="marketing">Marketing</option>
  //           </Select>
  //         </label>
  //         <label>
  //           <LabelText>Job title</LabelText>
  //           <Input
  //             type="text"
  //             placeholder="Job title"
  //             value={jobTitle}
  //             onChange={e => setJobTitle(e.target.value)}
  //           />
  //         </label>
  //         {error && <span style={{ color: 'red', marginLeft: '88px' }}>{error}</span>}
  //         <label>
  //           <LabelText>Level</LabelText>
  //           <Select onChange={e => setLevel(e.target.value)} value={level}>
  //             <option value="Intern">Intern</option>
  //             <option value="Junior">Junior</option>
  //             <option value="Mid">Mid</option>
  //             <option value="Senior">Senior</option>
  //           </Select>
  //         </label>
  //         <AddButton type="submit">
  //           <ButtonText>
  //             <span>Update</span>
  //           </ButtonText>
  //         </AddButton>
  //       </Form>
  //     </PopupContainer>
  //   </PopupOverlay>
  // );


  return (
    <PopupOverlay onClick={togglePopup}>
      <PopupContainer onClick={e => e.stopPropagation()}>
        <PopupTitle>Update Position</PopupTitle>
        <Form onSubmit={handleSubmit}>
          <LabelInputGroup>
            <LabelTextAligned>Category</LabelTextAligned>
            <Select value={category.toLowerCase()} onChange={e => setCategory(e.target.value)}>
              <option value="development">Development</option>
              <option value="design">Design</option>
              <option value="marketing">Marketing</option>
            </Select>
          </LabelInputGroup>
          <LabelInputGroup>
            <LabelTextAligned>Job title</LabelTextAligned>
            <Input
              type="text"
              placeholder="Job title"
              value={jobTitle}
              onChange={e => setJobTitle(e.target.value)}
            />
          </LabelInputGroup>
          {error && <span style={{ color: 'red', textAlign: 'end' }}>{error}</span>}
          <LabelInputGroup>
            <LabelTextAligned>Level</LabelTextAligned>
            <Select value={level} onChange={e => setLevel(e.target.value)}>
              <option value="Intern">Intern</option>
              <option value="Junior">Junior</option>
              <option value="Mid">Mid</option>
              <option value="Senior">Senior</option>
            </Select>
          </LabelInputGroup>
          {/* Description */}
          <LabelInputGroup>
            <LabelTextAligned>Description</LabelTextAligned>
            <TextAreaDescription
              placeholder="Enter job description"
              value={jobDescription}
              onChange={e => setJobDescription(e.target.value)}
            />
          </LabelInputGroup>
          {/* Responsibilities */}
          <LabelInputGroup>
            <LabelTextAligned>Responsibilities</LabelTextAligned>
            <InputGroups>
              {responsibilities.map((res, index) => (
                <FieldGroup key={index}>
                  <Input
                    type="text"
                    placeholder="Add responsibility"
                    value={res}
                    onChange={e => handleResponsibilityChange(index, e.target.value)}
                  />
                  <RemoveFieldButton
                    type="button"
                    onClick={() => handleRemoveResponsibility(index)}
                  >
                    <CgMathMinus size={16} />
                  </RemoveFieldButton>
                  <AddFieldButton type="button" onClick={handleAddResponsibility}>
                    <CgMathPlus size={16} />
                  </AddFieldButton>
                </FieldGroup>
              ))}
            </InputGroups>
          </LabelInputGroup>
          <LabelInputGroup>
            {/* Qualifications */}
            <LabelTextAligned>Qualifications</LabelTextAligned>
            <InputGroups>
              {qualifications.map((qual, index) => (
                <FieldGroup key={index}>
                  <Input
                    type="text"
                    placeholder="Add qualification"
                    value={qual}
                    onChange={e => handleQualificationChange(index, e.target.value)}
                  />
                  <RemoveFieldButton type="button" onClick={() => handleRemoveQualification(index)}>
                    <CgMathMinus size={16} />
                  </RemoveFieldButton>
                  <AddFieldButton type="button" onClick={handleAddQualification}>
                    <CgMathPlus size={16} />
                  </AddFieldButton>
                </FieldGroup>
              ))}
            </InputGroups>
          </LabelInputGroup>
          {/* Additional Information */}
          <LabelInputGroup>
            <LabelTextAligned>Additional Information</LabelTextAligned>
            <TextAreaAdditional
              placeholder="Enter additional information"
              value={additionalInfo}
              onChange={e => setAdditionalInfo(e.target.value)}
            />
          </LabelInputGroup>
          <ToggleGroup>
            <img src={timeIcon} alt="time icon" />
            <ToggleButton
              isActive={jobType === 'Full time'}
              onClick={() => setJobType('Full time')}
            >
              Full time
            </ToggleButton>
            <ToggleButton
              isActive={jobType === 'Part time'}
              onClick={() => setJobType('Part time')}
            >
              Part time
            </ToggleButton>
          </ToggleGroup>
          <AddButton type="submit">
            <ButtonText>
              <span>Update</span>
            </ButtonText>
          </AddButton>
        </Form>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default UpdatePositionPopup;
