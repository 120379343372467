import React, { useEffect, useState } from 'react';
import { NextButton, Page, PageNumbers, PaginationWrapper, PrevButton } from './pagination.styled';
import PaginationIcon from '../../../assets/icon/PaginationIcon';
import { PaginationProps } from './types';

const Pagination = ({
  count,
  perPage,
  activePage,
  handleChangePage,
  handleChangeCVPage
}: PaginationProps) => {
  const [active, setActive] = useState<number>(activePage);

  const pageCount = count && perPage ? Math.ceil(count / perPage) : 1;
  const pages = Array.from(Array(pageCount).keys());

  useEffect(() => {
    if (handleChangePage) {
      handleChangePage(active);
    }
  }, [active, handleChangePage]);

  useEffect(() => {
    if (handleChangeCVPage) {
      handleChangeCVPage(active);
    }
  }, [active, handleChangeCVPage]);

  const increment = () => {
    if (active !== pages.length) {
      setActive(prevState => prevState + 1);
    }
  };

  const decrement = () => {
    if (active > 1) {
      setActive(prevState => prevState - 1);
    }
  };

  return (
    <PaginationWrapper>
      <PrevButton onClick={decrement}>
        <PaginationIcon />
      </PrevButton>
      <PageNumbers>
        {pages.map(page => (
          <Page key={page + 1} onClick={() => setActive(page + 1)} active={active === page + 1}>
            {page + 1}
          </Page>
        ))}
      </PageNumbers>
      <NextButton onClick={increment}>
        <PaginationIcon />
      </NextButton>
    </PaginationWrapper>
  );
};

export default Pagination;
