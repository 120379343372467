import { useState } from 'react';
import Swal from 'sweetalert2';
import CartWrapper, {
  CartBody,
  CartFooter,
  CartHeader,
  Level,
  Position,
  State,
  Type,
  IconWrapper,
  IconButton,
  IconImage,
} from './positionCart.styled';
import { FaTrashAlt } from 'react-icons/fa';
import Button from 'components/atoms/button';
import { PositionCartProps } from './types';
import { useLocation } from 'react-router-dom';
import UpdatePositionPopup from 'components/molecules/updatePositionPopup/updatePositionPopup';
import { deleteCareer } from 'api/api';

const PositionCart = ({ data, onClick, onDelete }: PositionCartProps) => {
  const location = useLocation();
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);

  const handleDelete = async (id: string) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action will permanently delete the Job.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await deleteCareer(id);
        Swal.fire('Deleted!', 'The Job has been deleted.', 'success');
      }
      console.log(`Deleted item with ID: ${id}`);
      if (onDelete) {
        onDelete(id);
      }
    } catch (error) {
      console.error('Failed to delete item:', error);
      Swal.fire('Error', 'Failed to delete Job. Please try again.', 'error');
    }
  };

  const handleOpenUpdatePopup = () => {
    setIsUpdatePopupOpen(true);
  };

  const handleUpdateCareer = (updatedData: any) => {
    console.log('Updated data:', updatedData);
    setIsUpdatePopupOpen(false); // Close the popup after update
  };

  return (
    <>
      <CartWrapper>
        <CartHeader>
          <Type>{data.type}</Type>
          <State>{data.state}</State>
        </CartHeader>
        <CartBody>
          <Position>{data.position}</Position>
          <Level>{data.level}</Level>
        </CartBody>
        <CartFooter>
          <Button outlined onClick={onClick}>
            Apply Now
          </Button>
        </CartFooter>
        {location.pathname !== '/manageCareers' || (
          <IconWrapper>
            <IconButton onClick={handleOpenUpdatePopup}>
              <IconImage src={`${process.env.PUBLIC_URL}/media/pan.svg`} alt="pan" />
            </IconButton>
            <IconButton onClick={() => handleDelete(data._id.toString())}>
              <FaTrashAlt />
            </IconButton>
          </IconWrapper>
        )}
      </CartWrapper>
      {isUpdatePopupOpen && (
        <UpdatePositionPopup
          isOpen={isUpdatePopupOpen}
          togglePopup={() => setIsUpdatePopupOpen(false)}
          onUpdateCareer={handleUpdateCareer}
          careerData={{
            type: data.type,
            position: data.position,
            level: data.level,
            jobDescription: data.jobDescription,
            responsibilities: data.responsibilities,
            qualifications: data.qualifications,
            additionalInfo: data.additionalInfo,
            jobType: data.jobType,
            _id: data._id.toString(),
          }}
        />
      )}
    </>
  );
};

export default PositionCart;
