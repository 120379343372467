import styled from 'styled-components';
import { devices } from '../../styles/global';

export const OurPositions = styled.div`
  padding-top: 40px;
`;

export const BTitle = styled.h3`
  @media${devices.tabletS} {
    text-align: center;
  }
`;

export const CVRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 -10px 30px;
  gap: 20px 0;
  flex-wrap: wrap;
  @media${devices.mobileM} {
    margin: 0 auto 30px;
  }
`;

export const CVItem = styled.li`
  cursor: pointer;
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  padding: 9px 20px;
  text-transform: capitalize;
  border-radius: 8px;
  transition: all 150ms ease;
  &.active {
    border-color: #e2f2da;
    background-color: #e2f2da;
    color: var(--text-green);
  }
  &:hover {
    border-color: #e2f2da;
    background-color: #e2f2da;
    color: var(--text-green);
  }
`;

export const CardGrid = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
`;

export const Card = styled.div`
  width: 150px;
  height: 150px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #4caf50;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  overflow: hidden;

  &:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 70px;
  justify-content: center;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4caf50;
  font-size: 18px;
  transition: transform 0.2s ease, color 0.2s ease;

  &:hover {
    color: #388e3c;
    transform: scale(1.2);
  }
`;

export const LogoutButtonWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`;

export const LogoutButton = styled.button`
  background: #6ebd44;
  border-radius: 8px;
  padding: 9px 26px;
  text-transform: capitalize;
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--text-white);
`;
