import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DashedRow,
  // LeftSide,
  // PositionCartWrapper,
  // Requirements,
  SendCvButtonWrapper,
  SequenceCol,
  SequenceWrapper,
  // TitleDescription,
  // TopPart,
} from './currentJobPage.styled';
import Container from 'components/atoms/container';
// import PositionCart from 'components/molecules/positionCart';
import Sequence from 'components/atoms/sequence';
import Button from 'components/atoms/button';

import { sequence } from '../careerPage/mock';

// import CheckMarkIcon from 'assets/icon/CheckMarkIcon';
import dashedRow from 'assets/photos/dashed-row-horizontal.svg';
import { CartProps } from './types';
import Loader from '../../components/atoms/loader';
import { getCareerById } from '../../api/api';

const CurrentJobPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentJob, setCurrentJob] = useState<CartProps>({
    _id: '',
    type: '',
    state: '',
    position: '',
    jobDescription: '',
    level: '',
    responsibilities: [],
    qualifications: [],
    additionalInfo: '',
    jobType: '',
  });

  useEffect(() => {
    getCareerById(params.id).then(res => setCurrentJob(res.data));
  }, [params.id]);

  if (Object.keys(currentJob).length === 0) {
    return <Loader />;
  } else {
    return (
      <div>
        <Container>
          {/* <CurrentJobPageStyled>
            <LeftSide>
              <TopPart>
                <TitleDescription>
                  <h1>{currentJob.position}</h1>
                  {currentJob.jobDescription &&
                    currentJob.jobDescription.map(desc => <h4 key={desc.id}>{desc.text}</h4>)}
                </TitleDescription>
              </TopPart>
              <Requirements>
                {currentJob.experience && (
                  <div>
                    <h2>Experience:</h2>
                    <ul>
                      {currentJob.experience.map((item, index) => (
                        <li key={index}>
                          <CheckMarkIcon />
                          <h3>{item.text}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {currentJob.techSkills && (
                  <div>
                    <h2>Tech skills:</h2>
                    <ul>
                      {currentJob.techSkills.map((item, index) => (
                        <li key={index}>
                          <CheckMarkIcon />
                          <h3>{item.text}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {currentJob.softSkills && (
                  <div>
                    <h2>Soft skills:</h2>
                    <ul>
                      {currentJob.softSkills.map((item, index) => (
                        <li key={index}>
                          <CheckMarkIcon />
                          <h3>{item.text}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {currentJob.appreciated && (
                  <div>
                    <h2>Appreciated:</h2>
                    <ul>
                      {currentJob.appreciated.map((item, index) => (
                        <li key={index}>
                          <CheckMarkIcon />
                          <h3>{item.text}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Requirements>
            </LeftSide>
            <PositionCartWrapper>
              <PositionCart
                data={currentJob}
                onClick={() => navigate(`/sendCv/${currentJob._id}`)}
              />
            </PositionCartWrapper>
          </CurrentJobPageStyled> */}
          <SequenceWrapper>
            <DashedRow>
              <img src={dashedRow} alt="any" />
            </DashedRow>
            {sequence.map((item, index) => (
              <SequenceCol key={index}>
                <Sequence data={item} />
              </SequenceCol>
            ))}
          </SequenceWrapper>
        </Container>
        <SendCvButtonWrapper>
          <Button onClick={() => navigate(`/sendCv/${currentJob._id}`)}>Send CV</Button>
        </SendCvButtonWrapper>
      </div>
    );
  }
};

export default CurrentJobPage;
