import styled from 'styled-components';
import { devices } from '../../styles/global';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 25px 0 25px 0;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  margin-bottom: 25px;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
`;

export const Level = styled.div`
  display: flex;
  gap: 10px;
  font-size: 15px;
`;

export const Highlight = styled.span`
  color: var(--text-green);
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: #333;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Type = styled.div`
  padding: 9px 22px;
  border-color: #e2f2da;
  background-color: #e2f2da;
  color: var(--text-green);
  border-radius: 8px;
`;

export const UnderLine = styled.div`
  border: 1px solid #dfdede;
  margin: 20px 0 20px 0;
`;

export const Description = styled.div`
  margin-top: 16px;
  color: #333;
`;

export const TextTitle = styled.h3`
  font-size: 16px;
  color: var(--text-green);
  margin-bottom: 16px;
`;

export const DescriptionText = styled.p`
  font-size: 15px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 25px;
  font-weight: 400;
`;

export const ListItems = styled.ul`
  padding-left: '16px';
`;

export const ListItem = styled.li`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 50px;
  padding-top: 25px;
`;

export const EmailButton = styled.button`
  background-color: #6ebd44;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;

  @media ${devices.mobileL} {
    padding: 10px 20px;
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    background-color: #43a047;
  }
`;

export const CvButton = styled(EmailButton)`
  background-color: #6ebd44;
  &:hover {
    background-color: #43a047;
  }
`;

export const StyledFileUploadButton = styled.button`
  color: #6ebd44;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: white;
    background-color: #6ebd44;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const Checkmark = styled.span`
  display: inline-block;
  margin-left: 10px;
  color: #28a745;
  font-size: 18px;
  font-weight: bold;
`;
