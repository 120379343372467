import React from 'react';
import StyledPlusButton from './plusButton.styled';
import { FiPlus } from 'react-icons/fi';

interface PlusButtonProps {
  onClick: () => void;
}

const PlusButton: React.FC<PlusButtonProps> = ({ onClick }) => {
  return (
    <StyledPlusButton onClick={onClick}>
      Add new position <FiPlus className="plus-icon" />
    </StyledPlusButton>
  );
};

export default PlusButton;
